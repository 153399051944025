.lp-cover-image-wrapper-laptop {
  position: relative;
  min-height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../../assets/images/landing-page/landing-cover-laptop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 500px;
}
.lp-cover-img-laptop {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay-container {
  margin-left: 10%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-overlay {
  padding: 40px 30px 30px;
  width: 550px;
  background-color: #ffffff;
  border-radius: 0 0 100px 0;
}
.lp-cover-image-wrapper-mobile {
  display: none;
}
.mobile-landing-cover-img {
  display: none;
}
.lp-overlay h1 {
  margin-top: 20px;
  color: #212121;
}
.lp-overlay div {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}
.lp-work-container {
  margin-bottom: 126px;
  margin-top: 100px;
}
.lp-work-container h1 {
  color: #212121;
  text-align: center;
  margin-bottom: 30px;
}

.lp-work-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  width: 100%;
  padding: 20px 0;
}

.lp-work-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1; 
  margin: 0 20px; 
}

.lp-work-content img {
  height: 60px; 
  margin-bottom: 30px; 
}

.lp-work-content p {
  margin-top: auto; 
}

.lp-work-content > div {
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  word-wrap: break-word;
  text-align: center; 
}

.lp-work-content.content-center {
  margin-left: 0; 
  margin-right: 0; 
  align-items: center; 
}

.lp-ecopro-feature-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 100px 30px;
}
.lp-ecopro-feature-content-wrapper {
  padding-left: 60px;
}
.lp-ecopro-feature-container img {
  max-width: 100%;
  height: auto;
}
.lp-ecopro-feature-content-wrapper h1 {
  line-height: 120%;
  color: #212121;
}
.lp-ecopro-feature-content-wrapper ul {
  padding-left: 1.5rem;
}
.lp-ecopro-feature-content-wrapper li {
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  margin-bottom: 15px;
}
.lp-ep-feature-content {
  margin-top: 30px;
}

.lp-subscription-plans {
  padding: 100px 0px;
}
.lp-subscription-plans h1 {
  color: #212121;
  text-align: center;
}
.lp-sp-printer-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lp-sp-printer-type {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.lp-sp-printer-type > * {
  flex: 1;
}
.lp-sp-printer-card {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}
.lp-sp-printer {
  height: 40px;
  margin: 5px;
  text-align: center;
}

.lp-plans-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lp-plans-btn-wrapper {
  font-size: 32px !important;
}

.lp-sp-printer-type .border-curve {
  border: 2.5px solid #e4e4e4;
}
.lp-sp-card-active {
  border-radius: 10px;
  border: 2.5px solid #1965e1 !important;
}
.landing-page-carousel {
  margin-bottom: 30px;
}
.landing-page-carousel .slick-track {
  display: flex;
}
.landing-page-carousel .slick-list {
  padding-top: 44px !important;
}

.landing-page-carousel .slick-slide {
  height: inherit;
  padding: 25px 20px;
  background-color: #ffffff;
  transform: scale(0.95);
  border-radius: 6px;
}
.landing-page-carousel h2 {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 120% !important;
  color: #007e8f;
  padding-bottom: 5px;
  border-bottom: 1px solid #007e8f;
  margin-bottom: 0 !important;
}
.lp-c-plan-pages {
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  color: #777777;
}
.lp-c-plan-price {
  font-weight: 700;
  margin-top: 20px;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
}
.lp-faq-container {
  background-color: #ffffff;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.lp-faq-container h1 {
  color: #212121;
}
.lp-faq {
  max-width: 730px;
  margin-top: 30px;
}
.lp-faq .accordion-button::after {
  background-image: url("../../assets/images/landing-page/plus.svg");
}
.lp-faq .accordion-button {
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}
.lp-faq .accordion-button:not(.collapsed) {
  color: #212121;
  background-color: #ffffff;
  box-shadow: none;
}
.lp-faq .accordion-button:not(.collapsed)::after {
  background-image: url("../../assets/images/landing-page/minus.svg") !important;
}
.lp-faq .accordion-button:focus {
  border-color: #ffffff;
  outline: 0;
  box-shadow: none;
}
.get-started-print {
  background: #212121;
  padding: 100px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.get-started {
  font-size: 16px;
  line-height: 26px;
}
.get-started-print h1 {
  font-size: 32px;
  line-height: 120%;
  margin-top: 15px;
}
.gs-device-list-wrapper {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
}
.gs-device-list {
  justify-content: space-around;
  margin-left: 35px;
  margin-right: 35px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.landing-page-btn{
  height: 44px !important;
  width: fit-content !important;
  padding: 0 28.5px;
}

.lp-faq .accordion-flush .accordion-item .accordion-button {
  margin-right: 20px;
}

@media (min-width: 1440px) {
  .lp-cover-image-wrapper-laptop{
    height: 600px;
  }
}

@media (min-width: 768px) {
  .lp-sp-printer-type {
    max-width: 400px;
  }
  .get-started-print h1 {
    max-width: 700px;
  }
}

@media (max-width: 1023px) {
  .lp-overlay {
    width: 100%;
    padding: 60px 30px;
    position: relative;
    background-color: #ffffff;
    margin: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .lp-cover-image-wrapper-laptop {
    display: none;
  }
  .lp-cover-image-wrapper-mobile {
    display: block;
    max-width: 100%;
    width: 100%;
    margin: auto;
    background-image: none;
  }
  .mobile-landing-cover-img {
    max-width: 100%;
    width: 100%;
    height: 553px;
    margin: auto;
    display: block;
  }
  .lp-work-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .lp-work-content-wrapper {
    align-items: center; 
    flex-direction: column;
  }
  .lp-work-content {
    margin-top: 60px;
  }
  .lp-ecopro-feature-container {
    display: block;
    padding: 0;
  }
  .lp-ecopro-feature-container img {
    width: 100%;
    height: auto;
  }
  .lp-ecopro-feature-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 30px 100px;
  }
  .lp-ecopro-feature-content-wrapper h1 {
    font-size: 24px;
    text-align: center;
  }
  .lp-subscription-plans {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .lp-subscription-plans h1,
  .lp-sp-printer-type-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .lp-sp-plan-carousel-container {
    padding-left: 16px;
  }
  .lp-subscription-plans button {
    margin-left: 20px;
    margin-right: 20px;
  }
  .lp-subscription-plans h1,
  .get-started-print h1 {
    font-size: 24px;
    line-height: 120%;
  }

  .lp-faq-container {
    padding: 100px 30px;
  }
  .lp-faq-container h1 {
    font-size: 24px;
    line-height: 120%;
    text-align: center;
  }
  .landing-page-carousel,
  .lp-plans-btn-wrapper {
    display: block;
  }
  .landing-page-carousel .slick-dots {
    float: right;
    width: auto;
    margin-right: 30px;
    margin-top: 40px;
  }

  .landing-page-carousel .slick-dots li {
    position: relative;
    display: none;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .landing-page-carousel .slick-dots li.slick-active {
    display: inline-block;
  }
  .lp-overlay h1 {
    text-align: center;
    font-size: 28px;
    line-height: 120%;
  }
  .lp-overlay div {
    text-align: center;
  }
  .lp-cover-img {
    width: 100%;
  }
  .get-started-print {
    padding: 100px 30px;
  }
  .gs-device-list {
    margin-left: 25px;
    margin-right: 25px;
  }
}
.lp-plan-most-popular-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-plan-most-popular {
  text-align: center !important;
  margin-top: -45px;
  background-color: #007e8f;
  padding: 6px 15px;
  border-radius: 100px;
  color: #ffffff;
}
