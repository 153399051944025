.printer-model-list-wrapper{
    margin-bottom: 40px;
}
.no-results-found{
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.side-heading{
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;    
    color: #212121;
    margin-top: 10px;
    margin-bottom: 28px;
    margin-right: 10px;
}
.select-printer-type-wrapper{
    margin-top: 30px;
    margin-bottom: 40px;
}
.printer-type-list-wrapper{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.item, .invisible-item{
    background: #F9F9F9;;
    border-radius: 5px;
    height: 178px;
    margin: 5px;
    padding: 30px;
    cursor: pointer;
}
.invisible-item{
    background: none;
    border: none;
    cursor: auto;
}
.item img{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
}
.item p{
    font-weight: 400;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #212121;
}
.item:hover, .pre-selected{
    border: 1px solid #008393
}
/* SMARTPHONES PORTRAIT */
@media (min-width: 300px) {
     .select-printer-models-wrapper{
        margin: 0 15px;
    }
    .item, .invisible-item{
        height: 109px;
        padding: 15px;
    }
    .item p{
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;
    }
    .item img{
        width: 65px;
        height: 65px;
    }
    
    .printer-model-list-wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 10px; ; 
    }
} 
/* SMARTPHONES LANDSCAPE */
@media (min-width: 480px) {
    .select-printer-type-wrapper, .select-printer-models-wrapper{
        margin-left: 0px;
    }
    .printer-wrapper.variable-width{
        max-width: 464px !important;
    }
    .printer-model-list-wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 10px; ; 
    }
}
/* TABLETS PORTRAIT */
@media (min-width: 768px) {
    .select-printer-type-wrapper, .select-printer-models-wrapper{
        margin-left: 0px;
    }
    .item, .invisible-item{
        height: 137px;
    }
    .item p{
        margin-top: 20px;
        font-size: 14px;
        line-height: 16px;
    }
    .item img{
        width: 75px;
        height: 75px;
    }
    .printer-wrapper.variable-width{
        max-width: 600px !important;
    }
    
    .printer-model-list-wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        grid-gap: 10px; ; 
    }
}
/* TABLET LANDSCAPE / DESKTOP */
@media (min-width: 1024px) {
        .select-printer-type-wrapper, .select-printer-models-wrapper{
            margin-left: 0px;
        }
        .item, .invisible-item{
            width: 250px;
            height: 178px;
        }
        .item p{
            margin-top: 30px;
            font-size: 16px;
            line-height: 18px;
        }
        .item img{
            width: 100px;
            height: 100px;
        }
        .printer-wrapper.variable-width{
            max-width: 780px !important;
        }
        
        .printer-model-list-wrapper{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-gap: 10px;
        }
}    

