.updateAddress {
  padding: 0 45px 0 45px;
  text-align: center;
}
.updateAddress-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.updateAddress-no-img {
  margin-top: 109px;
  width: 150px;
  height: 150px;
  background: #c4c4c4;
}
.updateAddress-img{
  width: 100%;
  height: 120px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-top: 169px;
  width: 100%;
  height: 120px;
}
.updateAddress-img img{
  width: 120px;
  height: 120px;
}
.updateAddress-info {
  text-align: center;
}
.updateAddress-btn {
  width: auto !important;
  margin-top: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.updateAddress-nothanks {
  text-decoration: underline;
  margin-bottom: 60px;
  margin-top: 20px;
}
.updateAddress-btn-margin{
  margin-bottom: 60px;
}
