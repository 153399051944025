.hamburger-menu-desktop-wrapper{
    width: 262px!important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    z-index: -1;
}
.hamburger-menu-desktop-wrapper .header{
    padding: 0 30px!important;
    margin-top: 75px!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hamburger-menu-desktop-wrapper .body{
    padding: 0px 36px 30px 32px!important;
}

.active-link{
    color:#008393 !important;
}
.active-link .row div svg{
    fill:#008393;
    color:#008393
}
/**
    This rule applies in 3 places as listed below
    1. HamburgerMenu
    2.EcoproFeedback
    3.EcoproModal
*/
.navigation-disabled{
    pointer-events: none;
    cursor: default;
}