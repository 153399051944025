.plan-name-wrapper{
    width: auto;
    background: #008393;
    border-radius: 5px;
    padding: 20px;
}
.plan-name-wrapper div{
    color: #FFFFFF;
    margin-bottom: 5px !important
}
.plan-name-wrapper > p:first-child{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}
.plan-name{
    font-weight: bold;
    font-size: 16px;
    line-height: 0px;
}
.plan-offer{
    margin-top: 16px;
}
@media screen and (max-width: 568px) {
    .subscription-info-wrapper{
        padding: 10px;
    }
    .account-recent-orders-wrap{
        margin: 20px 0px 60px 0px;
    }
}
.enrolled-device-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    padding:30px;
    margin-top: 15px;
}
.enrolled-device-details-wrapper{
    width: 100%;
}
.enrolled-device-details-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.enrolled-device-status-wrapper{
    display: flex;
    align-items: baseline;
    margin: 8px 0 0px 15px;
}
.enrolled-device-details-contents{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.enrolled-device-details-contents p{
    margin-bottom: 0.5rem;
    font-style: normal;
}
.enrolled-device-name .device-model{
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
}
.enrolled-device-name .device-serialnumber{
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
}
.enrolled-device-date .device-date-label, .device-warranty-label{
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
}
.enrolled-device-date .device-date, .device-warranty-status{
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}
.enrolled-device-status-wrapper p{
    margin: -8px 0 0 5px;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.enrolled-device-image-wrapper img{
    width: 100px;
    height: 100px;
}
.enrolled-device-details-header h5{
    margin-bottom: 0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.enrolled-device-image-wrapper{
    text-align: center;
    color: #1965E1;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-right: 25px;
}

.enrolled-device-image-wrapper .change-device{
    cursor: pointer;
    margin-top: 10px;
}
.enrolled-device-outer-wrapper.show-xs .enrolled-device-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
}
.enrolled-device-header-img-wrapper{
    display: flex;
    flex-direction: column;
}
.enrolled-device-outer-wrapper.show-xs .enrolled-device-wrapper .enrolled-device-details-wrapper{
    width: auto;
}
.enrolled-device-outer-wrapper.show-xs .enrolled-device-wrapper .enrolled-device-details-wrapper .enrolled-device-details-contents{
    justify-content: normal;
    flex-direction: column;
}
.enrolled-device-outer-wrapper.show-xs .enrolled-device-wrapper .enrolled-device-details-wrapper .enrolled-device-details-contents .space-bottom{
    margin-bottom: 20px;
}
.enrolled-device-outer-wrapper.show-xs .enrolled-device-wrapper .enrolled-device-header-img-wrapper .enrolled-device-image-wrapper{
    margin: 25px;
}
.device-model.mobile-font, .device-date-label.mobile-font, .device-warranty-label.mobile-font{
    font-size: 11px;
    line-height: 13px;
}
.enrolled-device-details-header.mobile-font h5{
    font-size: 13px;
    line-height: 15px;
}
@media only screen and (max-width: 1023px) {
    .enrolled-device-outer-wrapper.show-xs, 
    .enrolledDevice-dashboard-block.show-xs,
    .device-not-connected-wrapper .show-xs,
    .show-xs{
        display: block;
    }
    .enrolled-device-outer-wrapper.show-lg, 
    .enrolledDevice-dashboard-block.show-lg,
    .device-not-connected-wrapper .show-lg,
    .show-lg{
        display: none;
    }
}
@media only screen and (min-width: 1024px) {
    .enrolled-device-outer-wrapper.show-xs, 
    .enrolledDevice-dashboard-block.show-xs,
    .device-not-connected-wrapper .show-xs,
    .show-xs{
        display: none;
    }
    .enrolled-device-outer-wrapper.show-lg, 
    .enrolledDevice-dashboard-block.show-lg,
    .device-not-connected-wrapper .show-lg,
    .show-lg{
        display: block;
    }
}