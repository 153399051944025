.overview-container .header{
    margin-top: 40px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid rgba(84,84,84, 0.25);
}
.overview-container .header .title h1{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #2D2D2D;
}
.last-updated-date-info{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #545454;
    position: relative;
    top: 7px;
}
.device-page-info-wrapper{
    margin-top: 25px;
}
.blocks{
    background: #FFFFFF;
    border: 1px solid rgba(84, 84, 84, 0.25);
    border-radius: 5px;
    padding: 20px;
}
.blocks.move-left{
    margin-left: 20px;
}
.blocks .block-title h5{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D2D2D;
}
.blocks .block-title p{
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #545454;
}
.blocks .block-content{
    text-align: center;
}
.blocks.move-left .block-title{
   margin-bottom: 40px;
}
.blocks .block-content button{
    width: 133px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}
.blocks .block-content p span{
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    color: #008393;
}
.blocks .block-content p{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #2D2D2D;
}
.device-details-info p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
}
.device-details-info p span{
    color: #2D2D2D;
    margin-left: 5px;
}
.device-details-btn-wrapper .ecopro_btn{
    padding: 12px 15px 12px 15px;
}

.device-details-btn-wrapper button{
    width: auto;
    height: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}
.device-details-btn-wrapper :nth-child(2){
    margin-left: 10px;
}
.customer-dashboard-swap-modal{
    text-align: center;
}
.customer-dashboard-swap-modal .modal-content{
    width: 365px;
    margin: 0 auto;
}
.customer-dashboard-swap-modal .modal-content .ecopro-modal-footer{
    justify-content: center;
}
.cs-agent.order-history-section{
    margin-bottom: 40px;
}
.order-history-section .no-records{
    font-size: 12px;
    text-align: center;
    margin-top: 22px;
}