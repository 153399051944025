.error-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84%;
  overflow: hidden;
  position: absolute;
}
.web-dashboard-error{
  position:  relative !important;
}
.withinComp{
  width: auto !important;
  height: 70% !important;
}

.error-info {
  margin-left: 45px;
  margin-right: 45px;
}
.refresh-btn {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.hamburger-menu-error{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
