.progress-container {
    margin-bottom: 60px;
    /* overflow: auto; */
    margin-top: 60px;
  }
  .progress-steps {
    counter-reset: step;
    padding: 0;
    margin: 0;
    overflow: auto;
  }
  .progress-steps li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    text-align: center;
    color: #777777;
    word-wrap: break-word;
  }
  .progress-steps li:before {
    width: 35px;
    height: 35px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #F5F5F5;
    display: block;
    text-align: center;
    margin: 0 auto 20px auto;
    border-radius: 50%;
    background: #F5F5F5;
    color: #777777;
    font-weight: 700;
    font-size: 14px;
  }
  .progress-steps li:after {
    width: 70%;
    height: 1px;
    content: '';
    position: absolute;
    background-color: #D9D9D9;
    top: 18px;
    left: -35%;
    z-index: -1;
  }
  .progress-steps li:first-child:after {
    content: none;
  }
  .progress-steps li.step-active, .progress-steps li.step-complete {
    color:  #545454;
  }
  .progress-steps li.step-complete:before {
    background-image: url('../../../assets/images/step-completed.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-color:  #008393;
    content: "";
    background-color: #FFFFFF;
  }
  .progress-steps li.step-active:before {
    border-color:  #008393;
    background: #008393;
    color: #fff;
  }
  .progress-steps li.step-complete + li:after {
    background-color:  #008393;
  }

  @media (min-width: 320px) and (max-width: 480px) {
  
    .progress-steps li.step-complete:before{
      background-size: 10px;
    }

    .progress-steps li:before{
      width: 25px;
      height: 25px;
      font-size: 10px;
      line-height: 20px;
    }

    .progress-steps li{
      font-size: 12px;
      line-height: 14px;
    }

    .progress-steps li:after{
      width: 40%;
      top: 12px;
      left: -20%;
    }
    
  }

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  
  /* CSS */
  
}
 