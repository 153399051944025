.average-pages-used-wrapper {
  margin-top: 30px;
  padding: 0 0px 60px 0px;
}
.average-page-trends {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #212121;
  margin-bottom: 10px;
}
.average-pages-used-content {
  background: #ffffff;
  padding: 20px 14px 20px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #545454;
  border-radius:5px
}
.trends-header {
  margin-bottom: 5px;
}
.average-page-count {
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  color: #212121;
}

.trends-date{
  margin-top: 12px;
}

.trends-bar-chart-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

canvas#chart{
  margin-top: 10px !important;
  margin-left: -7px !important;
  height: 300px !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
  canvas#chart{
    height: 250px !important;
  }

}
@media  (max-width: 767px) {
  canvas#chart{
    height: 200px !important;
  }
}

