.promotion-banner-wrapper{
    background: #E52053;
    width: 100%;
    padding: 24px 50px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.promotion-banner-with-button{
    justify-content: space-between !important;
}

.promotion-banner-wrapper .landing-page-btn{
    margin-left: 15px;
    background-color: #fff !important;
    border: #fff !important;
    color: #E52053 !important;
    border-radius: 0px !important;
}
.promotion-banner-wrapper .landing-page-btn strong{
    text-transform: uppercase;
}
.promotion-text{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}
/* .subtext-promotionbanner{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */
.promotion-text .subtext-promotionbanner, .promotion-text .plan-limited-offer{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.promotion-text .save-more{
    font-size: 30px;
}

/* mobile */
@media (max-width: 767px) {
    .promotion-banner-wrapper{
        flex-direction: column;
        padding: 15px;
        text-align: center;
    }
    .promotion-banner-wrapper .landing-page-btn{
        margin-top: 20px;
    }
    .promotion-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}