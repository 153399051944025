.brother-plus-dashboard-initial-popup-modal{
    width: 400px;
}
.brother-plus-dashboard-popup{
    color: #4D4D4D;
}
.brother-plus-dashboard-popup img{
    margin: 0 0 15px 0;
}
.brother-plus-dashboard-popup h1{
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.brother-plus-dashboard-popup p{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0;
}
.brother-plus-benefits-wrapper ul{
    list-style-type: none;
    padding-left: 10px;
}
.brother-plus-benefits-wrapper ul li{
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    position: relative;
    padding-left: 30px;
}
.brother-plus-benefits-wrapper ul li::before{
    content: url("../../../assets/images/BrotherPlus/tick-icon.svg");
    margin-right: 15px;
    position: absolute;
    left: 0px;
    top: 0px;
}
.brother-plus-dashboard-popup .continue-btn-wrapper button{
    height: 40px;
    width: 140px;
    background: #4568E6;
    border-radius: 8px;
    margin-top: 15px;
}
.popup-terms-and-conditions{
    display: flex;
    margin-bottom: 10px;
}
.popup-terms-and-conditions label{
    margin: 0 0 0 10px;
}
.brother-plus-dashboard-popup .learn-more-wrapper a{
    text-decoration: underline;
    font-style: italic;
}