.pa-progress-bar-container{
    padding: 20px;
    background-color: #ffffff !important;
}

.pa-progress-bar-header{
    color: #212121;
    font-size: 18px;
}

.pa-progress-bar-info{
    color: #545454;
    font-size: 14px;
}

.pa-progress-bar-data-wrapper{
    margin-top: 20px;
    
}

.pa-progress-bar-data{
    font-size: 12px;
}

.pa-progress-bar-value{
    font-size: 12px;
    font-weight: 700 !important;
}

.pa-progress-bar-data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pa-progress-bar-data-wrapper  .left-div {
    text-align: left;
}
.pa-progress-bar-data-wrapper  .right-div {
    text-align: right;
}

.left-div {
    flex: 1;
}

.right-div {
    flex: 1; 
}

.pa-progress-bar-container .progress{
    height: 10px !important;
    margin-right: 0 !important;
}
.pa-progress-bar{
    margin-top: 15px;
}
