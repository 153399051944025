.account-info-wrapper{
    margin-top: 14px;
}
.dashboard-display-device{
    border-radius: 5px;
    background: #ffffff; 
    margin-bottom: 17px; 
}
.dashboard-display-device .title{
    text-align: left !important;
    padding: 30px;
}
.display-device-content{
    padding: 0px 30px 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard-display-device .device-img{
    width: 150px;
    height: 150px;
    display: flex;
    overflow: hidden;
}
.dashboard-display-device img{
    width: 150px;
    height: 150px;
}
.display-device-img-skeleton{
    width: 150px;
    height: 150px;
    margin: 20px auto
}
.display-device{
    margin-top: 5px;
}
.change-device-btn{
    display: flex;
    justify-content: center;
    padding: 0px 15px 30px 15px;
}
.pages-left, .pages-rolled-over, .pages-overages-wrapper{
    background: #FFFFFF;
    border-radius: 5px;
}
.websignup-connect-device-dashboard-wrapper .pages-left, 
.websignup-connect-device-dashboard-wrapper .pages-rolled-over, 
.websignup-connect-device-dashboard-wrapper .pages-overages-wrapper{
   opacity: 0.5;
}
.pages-rolled-over, .pages-overages-wrapper{
    padding: 20px;
}

.pages-left h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.pages-left-sm {
    padding: 30px 30px 0 30px;
}
.pages-left-lg{
    padding: 20px 0 0 20px !important;
}

.overages-wrapper h4, .overages-wrapper .pages-rolled-over-progress p{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.overages-wrapper{
    margin-left: 15px;
    width: 39%;
}
.overages-wrapper .pages-rolled-over-progress p{
    line-height: 0px;
}
.pages-left-progress{
    background: #008393;
    transform: rotate(-90deg);
}
.pages-left > div > div:first-child{
    width: 55% !important;
    margin: 35px auto;
}
.pages-left p{
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
}

div[data-test-id="CircularProgressbarWithChildren__children"]{
    flex-direction: initial !important;
}
.pages-consumed-count, .pages-leftover-count{
    font-weight: bold;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #008393;
}
.pages-leftover-count{
    font-size: 13px;
    line-height: 15px;
    color: #545454;
    margin-left: 5px;
}
.pages-rolled-over-progress{
    margin-top: 25px;
}
.pages-rolled-over .header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.pages-rolled-over .header span{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin-left: 40px;
}
.progress{
    margin-right: 20px;
}
.progress, .progress-bar{
    border-radius: 25px;
}
.progress-bar{
    background-color: #008393;
}
.pages-overages-wrapper{
    margin-top: 15px;
}
.pages-overages-wrapper .header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.pages-overages-wrapper .header span{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin-left: 30px;
}
.slick-slide .overages-wrapper{
    margin-left: 0px;
}
.slick-slide .pages-overages-wrapper{
    margin-top: 0px;
}
.account-info-wrapper .slick-list{
    background: rgb(255, 255, 255);
    border-radius: 5px !important;
}
.slick-slide .pages-rolled-over-progress{
    margin: 105px auto;
}
.account-info-carousel-view .slick-dots{
    margin-bottom: 30px !important;
}
.react-loading-skeleton{
    background-color: #e9ecef !important;
}
.circular-skeleton-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.circular-skeleton{
    margin: 35px auto;
    width: 108px;
    height:108px !important;
}
.para-skeleton1{
    width: 80% !important;
    margin: 0 10% 3% 10% !important;
}
.para-skeleton2{
    width: 80% !important;
    margin: 0 10% 10% 10% !important;
}
.rolled-over-overages-skeleton{
    margin-right: 20px !important;
    width: 93% !important;
}
@media (min-width: 481px) and (max-width: 900px){
    .overages-wrapper{
        width: 47%;
    }
}
@media screen and (min-width: 920px) and (max-width: 1281px) {
    .overages-wrapper{
        width: 39%;
    }
}
.disabled-dashboard-blocks,
.disabled-dashboard-blocks .pages-left, 
.disabled-dashboard-blocks .pages-rolled-over, 
.disabled-dashboard-blocks .pages-overages-wrapper{
   opacity: 0.5;
}