
.account-recent-orders-wrap{
    background-color: #ffffff;
    border-radius: 5px;
    color: #545454;
    padding: 30px;
    margin: 20px 0px 60px 0px;
}
.orderslist-pg{
    background: none;
}
.account-recent-orders-wrap a{
    cursor: pointer;
}
.account-recent-orders-wrap .header{
/* .account-recent-orders-wrap .content{ */
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.ordershome-pg .order-list-wrapper{
    margin-top: 30px;
}
.orderslist-pg .order-list-wrapper{
    margin-top: 20px;
}
.account-recent-orders-wrap .header h4, .account-recent-orders-wrap .content h4{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}
.account-recent-orders-wrap .content h4{
    color: #008393;
}
.account-recent-orders-wrap .content h5{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
}
.account-recent-orders-wrap .header a{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
    color: #008393;
}
.account-recent-orders-wrap .content .order-list{
    display: flex;
    align-items: baseline;
}
.account-recent-orders-wrap .content .order-list .order-status-wrapper{
    margin-left: 8px;
}
.account-recent-orders-wrap .content .order-list h4{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.account-recent-orders-wrap .content .order-list p{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.account-recent-orders-wrap .content .order-icon{
    background: #545454;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    position: relative;
    top: 8px;
}
.account-recent-orders-wrap .content .order-icon:after{
    content: url('../../assets/images/connecting-lines.svg');
    display: table;
    position: relative;
    left: 16px;
    top: 2px;
}
.orderslist-pg .order-list-wrapper .content .content-list-wrapper:last-child .order-icon:after{
    content: "";
}
.ordershome-pg .order-list-wrapper .content:last-child .content-list-wrapper:last-child .order-icon:after{
    content: "";
}
.account-recent-orders-wrap .content .order-icon img{
    padding: 10px;
}
.order-detail-btn{
    background: #F5F6F8;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    position: relative;
    top: 10px;
}
.order-detail-btn a{
    color: #545454;
}
.content-list-wrapper{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.order-status-wrapper h4{
    color: #545454;
}
.orderslist-pg .content{
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 15px;
}
.account-recent-orders-wrap h1{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #545454;
}
.order-search{
    margin-top: 30px;
    position: relative;
}
.order-search .form-control{
    border-radius: 5px;
    background-color: #E4E4E4;
    height: 32px;
    border-color: #e4e4e4;
    /* background:url("../../assets/images/search-icon.svg") no-repeat scroll 7px 7px; */
}
.order-search .search-icon:after{
    content: url("../../assets/images/search-icon.svg");
    display: table;
    position: relative;
    top: -24px;
    left: 10px;
}
#orders-search{
    padding-left: 30px 
}
.no-orders-txt{
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #ccc;
}
.square-skeleton{
    width: 35px;
    height: 35px;
}
.websignup-connect-device-dashboard-order-wrapper{
    opacity: 0.5;
}