.pages-used-outer-wrapper{
    background: #FFFFFF;
    border-radius: 5px;
}
.pages-used-inner-wrapper{
    padding: 20px;
}

.subscription-month-dropdown-wrapper{
    width: 90px;
    margin: 0 auto;
    position: relative
}
.subscription-month-dropdown-wrapper .dropdown-arrow{
    margin-left: 5px;
}
.subscription-month-dropdown{
    appearance: none;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
    margin-top: 20px;
    z-index: 2;
    background: transparent;
    position: relative;
    width: 128px;
    padding-right: 20px;
    border-radius: 0 !important;
    text-align: center;
}
.subscription-month-dropdown:focus-visible{
    outline-color:none;
    outline-width: 0px;
}
.pages-used-data-representation-wrapper{
    margin: 15px auto;
}
.progress-bar-value-range-wrapper{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
}
.progress-bar-value-range-wrapper .min-value{
    margin-left: 3px;
}
.progress-bar-value-range-wrapper .max-value{
    margin-right: -4px;
}
.semicircle-percent-value{
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0;
    top: -80px;
}
.semicircle-text{
    left: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: -25px;
}
.pages-used-data-representation-wrapper div div div[data-test-id="CircularProgressbarWithChildren__children"]{
    display: block !important;
    margin-top: 0px !important;
    top: 57%
}
.pages-used-progressbar-labels-wrapper{
    margin-top: 25px;
}
.pages-used-progressbar-labels-wrapper .label{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}
.pages-used-progressbar-labels-wrapper .label p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px;
    margin-bottom: 10px;
}
.pages-used-plan-details-wrapper{
    margin-top: 20px;
}
.pages-used-plan-details-wrapper p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.pages-used-plan-details-wrapper .plan-name{
    color: #212121;
}

.pages-used-inner-wrapper .spinner-wrapper {
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: unset;
    top: 0;
    left: 0;
    transform: none;
}
.pages-used-error-info{
    display: flex;
    justify-content: center;
    height: 145px;
    margin-top: 45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
/* .subscription-month-dropdown-wrapper::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    text-align: center;
    font-size: 28px;
    color:blue;
    background-color: blue;
    pointer-events: none;
    display:block;
    z-index:1;
  } */
  
.subscription-month-dropdown-wrapper::after{
    content: url('../../../assets/images/dropdown-arrow.svg');
    position: absolute;
    top: 16px;
    right: -37px;
}