.side-heading{
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    color: #212121;
    margin-top: 10px;
    margin-bottom: 28px;
    margin-right: 10px;
}
.select-printer-type-wrapper-i{
    margin-top: 30px;
    margin-bottom: 40px;
}
.printer-type-list-wrapper-i{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    margin-bottom: 60px;
}
.item-i{
    flex-grow: 1;
    margin: 0 15px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.10);
    border: 2.6px solid transparent;
    cursor: pointer;
}
.invisible-item{
    background: none;
    border: none;
    cursor: auto;
}
.item-i img{
    width: -webkit-fill-available;
    height: 150px;
    margin: 0 auto;
    display: block;
}
.pt-name{
    font-weight: 700;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: var(--neutral-white, #FFF);;
    background: var(--neutral-d-grey, #4D4D4D);
    margin-bottom: 0 !important;
    
}
.pt-description{
    color: var(--neutral-black, #212121);
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0 !important;
    padding: 18px 24px;
}
.item-i:hover, .pre-selected-i{
    border: 2.6px solid var(--primary-m-blue, #1965E1);
}
.pre-selected-text-i,.item-i:hover .pt-name{
    background: var(--primary-m-blue, #1965E1) !important;

}
/* SMARTPHONES PORTRAIT */
@media (min-width: 300px) {
    .select-printer-type-wrapper-i{
        margin: 0 15px;
    }
    .pt-name{
        font-size: 12px;
        line-height: 14px;
        padding: 9px;
        margin-bottom: 0 !important;
    }
    .item-i img{
        height: 120px;
    }
    .printer-type-list-wrapper-i{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 10px;
    }
   
} 
/* SMARTPHONES LANDSCAPE */
@media (min-width: 480px) {
    .select-printer-type-wrapper-i, .select-printer-models-wrapper{
        margin-left: 0px;
    }
    .printer-wrapper.variable-width{
        max-width: 464px !important;
    }
    .printer-type-list-wrapper-i{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 10px;
    }
}
/* TABLETS PORTRAIT */
@media (min-width: 768px) {
    .select-printer-type-wrapper-i, .select-printer-models-wrapper{
        margin-left: 0px;
    }
    .pt-name{
        font-size: 14px;
        line-height: 16px;
        padding: 11px;
    }
    .item-i img{
        height: 120px;
    }
    .printer-wrapper.variable-width{
        max-width: 600px !important;
    }
    
}
/* TABLET LANDSCAPE / DESKTOP */
@media (min-width: 1024px) {
        .select-printer-type-wrapper-i, .select-printer-models-wrapper{
            margin-left: 0px;
        }
        .pt-name{
            font-size: 16px;
            line-height: 18px;
            padding: 11px;
            margin-bottom: 0 !important;
        }
        .item-i img{
            height: 150px;
        }
        .printer-wrapper.variable-width{
            max-width: 780px !important;
        }
       
}   

@media (max-width: 767px) {
    .item-i{
        margin: 0 6px !important;
    }
}
