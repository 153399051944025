.feedback-menu-container {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .feedback-menu-wrapper {
    cursor: pointer;
    padding: 30px 32px 40px 32px;
    border-top: 1px solid rgba(84, 84, 84, 0.1);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
  .feedback-menu-content {
    margin-left: 9px;
    margin-right: 9px;
  }
  .feedback-menu-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  .feedback-menu-info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }