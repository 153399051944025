#ecopro-overall-feedback-modal .modal-content{
    padding: 20px;
    overflow-y: auto;
    max-height: 350px;
}
#ecopro-overall-feedback-modal .modal-content .signup-feedback-wrapper{
    margin-top: 0;
}
#ecopro-overall-feedback-modal .modal-content .signup-feedback-wrapper .header{
    width: 100%;
}
#ecopro-overall-feedback-modal .modal-content .signup-feedback-wrapper h1{
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #545454;
    text-align: left;
}

#ecopro-overall-feedback-modal .modal-content .signup-feedback-wrapper .signup-feedback-content-wrapper{
    padding: 0;
    margin: 15px 0 0 0;
}
#ecopro-overall-feedback-modal .modal-content .signup-feedback-wrapper .signup-feedback-content-wrapper .signup-feedback-comments-wrapper div textarea{
    height: 75px;
}
#ecopro-overall-feedback-modal .modal-content  .signup-feedback-buttons-wrapper{
    margin-top: 15px;
}
#ecopro-overall-feedback-modal .modal-content  .signup-feedback-buttons-wrapper .ecopro_btn{
    width: 150px;
    height: 38px;
}
.error-displaying-modal .modal-content{
    height: 200px;
}
.error-displaying-modal .modal-content .error-content{
    width: 95%;
}