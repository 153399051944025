.confirm-cancellation {
  margin-top: 35px;
}
.confirm-cancellation .header {
  padding: 10px 45px 10px 45px;
}
.confirm-cancellation .sub-header {
  padding: 0px 45px 40px 45px;
}

.cancel-confirmation-img {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-confirmation-img img,
.swap-confirmation-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.swap-confirmation-img-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swap-confirmation-img {
  width: 150px;
  height: 150px;
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}

.confirm-cancellation .list {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  text-align: center !important;
  padding: 40px 30px 30px 30px;
}
.confirm-cancellation .list ul {
  margin-bottom: 0rem !important;
  padding-left: 15px !important;
}
.confirm-cancellation .list li {
  margin-bottom: 10px;
  text-align: start !important;
}
.confirm-cancellation .confirm-cancellation-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 20px 30px;
}
.confirm-cancellation .cancellation-link a {
  color: #008393 !important;
  text-decoration: underline;
}
.confirm-cancellation .cancellation-link {
  margin-bottom: 53px;
}
