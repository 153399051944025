.my-account-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-img {
  width: 100px;
  height: 100px;
  margin-top: 35px;
}
.profile-img img{
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.profile-change {
  margin-top: 10px;
  text-decoration: underline;
}
.my-account-card {
  overflow-wrap: break-word;
  margin-top: 30px;
  padding: 0px 15px 0px 15px;
}
.my-account-content {
  background: #ffffff;
  border-radius: 5px;
  padding: 30px !important;
}
.change-device-card {
  color: #ffffff;
  fill: #ffffff;
  background: #008393 !important;
}
.update-personal {
  padding: 17px 30px !important;
}
.plan-border > :not(:first-child):not(:last-child) {
  border-bottom: 1px solid rgba(84, 84, 84, 0.1);
}
.my-account-edit-btn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.plan-type-header {
  font-size: 10px;
  line-height: 11px;
}
.pb-15 {
  padding-bottom: 15px;
}
.plan-occasional {
  font-size: 14px;
  margin-top: 8px;
}
.payment-border {
  padding-top: 15px;
}
.mb-60 {
  margin-bottom: 60px;
}
.right-arrow-white {
  fill: #ffffff;
  color: #ffffff;
}
@media (min-width: 481px) and (max-width: 900px) {
  .my-account-card {
    padding: 0px 50px 0px 50px;
  }
}
@media screen and (min-width: 920px) and (max-width: 1281px) {
  .my-account-card {
    padding: 0px 50px 0px 50px;
  }
}
