/* styles for tablet landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)  {
  .post-enrollment-wrapper{
    margin-left: 34% !important;
  }
  .post-enrollment-wrapper .desktop-app-back-btn .page-title-back-btn{
    left: 27.5% !important;
  }
}
/* styles for laptops and desktops */
@media only screen and (min-width: 1024px) {
    .customer-header-desktop, 
    .payment-option-desktop-step-header, 
    .payment-desktop-step-header,
    .desktop-mobileconnect-forms, 
    .desktop-device-list,
    .post-enrollment-wrapper .desktop-app-back-btn,
    .address-edit-form-desktop-app-wrapper,
    .account-info-normal-view {
        display: block;
    }
    .customer-header-mobile, 
    .payment-option-mobile-step-header, 
    .payment-mobile-step-header,
    .mobile-forms, 
    .mobile-device-list,
    .post-enrollment-footer,
    .address-edit-form-mobile-app-wrapper,
    .account-info-carousel-view {
        display: none;
    }
  /* button */
  .default {
    background-color: #1965e1;
    border: 1px solid #1965e1;
  }
  /* confirm device */
  .confirm-device-link a {
    color: #1965e1;
  }
  /* device list */
  .desktop-device-list .item{
    height: auto !important;
  }
  .desktop-device-list .item .model {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }
  .desktop-device-list .item .serial-number {
    margin-top: 0 !important;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    word-wrap: break-word;
  }
  .post-enrollment-wrapper{
    width: 600px;
    margin-left: 39%;
    margin-top: 80px;
  }
  .post-enrollment-wrapper div.error-content{
    width: auto;
  }
  .post-enrollment-header{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
  }
  .post-enrollment-wrapper .updateAddress .updateAddress-content .updateAddress-img{
    margin-top: 25px !important; 
  }
  .post-enrollment-header .web-header-outer-wrapper .header-right{
    top: 8.5px;
  }
  .post-enrollment-header .web-header-outer-wrapper .header-right div img{
    width: 35px;
    height: 35px;
  }
  .post-enrollment-wrapper .order-details{
    padding: 0 50px 50px 50px;
  }
  .post-enrollment-wrapper .desktop-app-back-btn .page-title-back-btn{
    left: 22.5%;
  }
  /** Dashboard Rules */
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper{
    margin-left: 10px;
    margin-top: 0px;
  }
  .dashboard-overview-with-swap-device .row .swap-device-desktop-app.col-5, 
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper.col-5, 
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-rolled-over.col-5 {
    width: 48.666667%;
  }
  .dashboard-overview-with-swap-device .row  .pages-left{
      margin-left: 10px;
      height: 385px;
  }
  .dashboard-overview-with-swap-device .row .dashboard-display-device{
    height: 385px;
  }
  .dashboard-overview-with-swap-device .row .overages-wrapper{
    width: 100%;
    margin-left: 0px;
  }
}

/* styles for portable devices like mobile & tablets */
@media only screen and (max-width: 1023px) {
    .customer-header-desktop, 
    .payment-option-desktop-step-header, 
    .payment-desktop-step-header,
    .desktop-mobileconnect-forms, 
    .desktop-device-list,
    .post-enrollment-wrapper .desktop-app-back-btn,
    .address-edit-form-desktop-app-wrapper,
    .account-info-normal-view {
        display: none;
    }
    .customer-header-mobile, 
    .payment-option-mobile-step-header, 
    .payment-mobile-step-header,
    .mobile-forms, 
    .mobile-device-list,
    .address-edit-form-mobile-app-wrapper,
    .account-info-carousel-view {
        display: block;
    }
    /** Dashboard Rules */
    .dashboard-overview-with-swap-device .row .swap-device-desktop-app.col-5{
      width: 48.666667%;
    }
    .dashboard-overview-with-swap-device .row  .pages-left{
      margin-left: 10px;
      height: 385px;
    }
    .dashboard-overview-with-swap-device .row .dashboard-display-device{
      height: 385px;
    }
    .dashboard-overview-with-swap-device .row .overages-wrapper{
      width: 100%;
      margin-left: 0px;
    }
    .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper{
      margin-left: 10px;
      margin-top: 0;
    }
    .plans-learn-more-container{
      flex-direction: column;
      align-items: center;
    }
    .plans-learn-more-container h5{
      font-size: 22px;
      line-height: 24px;
      margin-top: 10px;
    }
    .plans-learn-more-container .learn-more-button{
      margin: 10px 0px 20px 0px;
    }
}
