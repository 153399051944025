.print-activity-wrapper h1{
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #212121;
    margin-bottom: 0px;
}
.print-activity-wrapper{
    padding: 20px;
}
.pa-progress-bar-wrapper{
    margin-top: 10px;
}
.print-activity-csagent-error{
    margin-top: 100px;
}