.email-log-container{
    margin-top: 40px;
}

.email-log-error{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
}
.email-log-container .table>:not(:first-child){
    border-top: 1.5px solid !important;
}