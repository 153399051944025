.ecopro_btn {
  width: 330px;
  height: 55px;
  border-radius: 5px;
  font-family: Arial;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.default {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  background-color: #008393;
  border: 1px solid #008393;
}

.web-signup, .epic-blue-btn{
  font-weight: 400 !important;
  background-color: #1965E1;;
  border: 1px solid #1965E1;;
}
.web-signup-form{
  width: 200px !important;
  height: 44px !important;
}

.btn-f-14 {
  font-size: 14px !important;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

@media screen and (max-width: 600px) {
  .ecopro_btn {
    width: 330px;
    height: 55px;
    border-radius: 5px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }
}
.white-bg-btn{
  background: #FFFFFF !important;
  color: #008393 !important;
  border: 1px solid #008393 !important;
}
.cancel-subsc{
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}
.reactivation-button .ecopro_btn{
  width: auto;
  height: auto;
}
