.slick_carousel-web{
    margin-top: 20px;
}
.slick_carousel-web .slick-slide {
    background: #ffffff;
    border-radius: 5px;
    transform: scale(0.95);
}
.slick_carousel-web .slick-track{
    display: flex !important;
    align-items: flex-end !important;
}
.web-plan{
    width: 330px !important;
    border: 1px solid #E4E4E4;
    display: block !important;
    overflow-wrap: break-word;
}
.plan-feature-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    list-style-type: none;
}
.plan-feature li{
    display: flex;
    margin-bottom: 13px;
}
.plan-feature img{
    width: 16px;
    height: 12px;
}
.plan-feature span{
    font-weight: 400;
    line-height: 16px;
    font-size: 14px;
    margin-left: 15px;
}
.recomend-highlight{
    background: rgba(0, 131, 147, 0.05);
    border: 1px solid #008393;
}
.web-plan .plan-offer{
    margin: -1px;
    background: #008393;
    color: #FFFFFF;
    border-radius: 5px 5px 0px 0px;
    padding: 7px;
}
.web-plan .plan-content-wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
    flex-direction: column;
}
.web-plan .recomended{
    display: flex;
    background-color: #1965E1;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.web-plan .recomended span{
    margin-left: 5px;
}
.web-plan .plan-name{
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 5px;
}
.web-plan .plan-price{
    margin-top: 15px;
}
.web-plan .price-strike{
    position: relative;
}
.web-plan .price-strike::before{
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  opacity: 0.8;
  
  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}
.web-plan .plan-price :first-child{
    font-size: 32px;
    line-height: 37px;
}
.web-plan .pages-per-month{
    font-size: 14px;
    line-height: 16px;
}
.web-plan .per-month sup{
    vertical-align: super;
    font-size: 10px !important;
}

.web-plan .promotion-type{
    color: var(--primary-m-blue, #1965E1);
    font-size: 14px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.web-plan .promotion-type.promotion-banner-type{
    color: #E52053;
}
.web-plan .plan-btn-wrapper{
    padding: 0 30px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.web-plan .plan-btn-wrapper .web-plan-btn{
    height: 44px;
}
.web-plan .plan-includes{
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;                  
    padding: 20px 30px 15px 30px;
}
.slick_carousel-web .slick-dots{
    position: initial !important;
}
.web-plan-list{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 0 30px 30px 30px;
}
.web-plan-list .col-10{
    text-align: left;
}
.web-plan-list .row{
    margin-bottom: 13px;
}
.web-plan-list img{
    width:16px;
    height: 16px;
}
.plan-price-hide{
    visibility: hidden;
}
.plan-btn-wrapper button{
    border-radius: 0 !important;
}
.slick_carousel-web .slick-prev,.slick_carousel-web .slick-next{
    display:none !important;
  }
@media (max-width: 767px) {
    .slick_carousel-web{
        margin-top: 10px;
        margin-bottom: 80px;
    }
    .web-plan{
        width: 300px !important;
    }
    .web-plan .plan-content-wrapper{
        padding: 20px 25px 0 25px;
    }
    .web-plan .plan-btn-wrapper {
        margin-bottom: 20px;
        padding: 0 25px;
    }
    .web-plan .plan-includes {
        padding: 20px 25px 15px;
    }
    .web-plan-list {
        padding: 0 25px 30px;
    }
    .web-plan hr{
        margin: 15px 25px 0px 25px;
    }
}
@media (max-width:375px){
    .web-plan{
        width: 250px !important;
    }
    .web-plan .plan-content-wrapper{
        padding: 20px 15px 0 15px;
    }
    .web-plan .plan-btn-wrapper {
        padding: 0 15px;
    }
    .web-plan .plan-includes {
        padding: 20px 15px 10px;
    }
    .web-plan-list {
        padding: 0 15px 20px;
    }
    .web-plan hr{
        margin: 15px 15px 0px 15px;
    }
    .web-plan .plan-offer, .web-plan .recomended span,
    .web-plan .plan-includes{
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
    }
    .web-plan .plan-name{
        font-size: 18px;
        line-height: 21px;
    }
    .web-plan .plan-price :first-child {
        font-size: 26px;
        line-height: 30px;
    }
    .plan-feature span, .web-plan .pages-per-month{
        font-weight: 400;
        line-height: 14px;
        font-size: 12px;
    }
    .web-plan-list{
        font-size: 10px;
        line-height: 11px;
    }
    .plan-feature li{
        display: flex;
        margin-bottom: 8px;
    }
    .plan-feature img{
        width: 13px;
        height: 10px;
    }
    .web-plan-list img {
        width: 14px;
        height: 11px;
    }
}

@media (max-width:320px){
    .plan-feature-wrapper{
        padding: 0 15px;
    }
}