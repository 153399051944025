.menu__btn {
  display: flex;
  align-items: center;
  width: 25px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  background: #ffffff;
  border-radius: 150px;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.navbar{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.navbar-toggler{
  color: #545454 !important;
  border-color: #0D2EA0 !important;
  padding: 0 !important;
  margin-right: 0 !important;
}
.navbar-toggler:focus{
  box-shadow:0 0 0 0rem !important
}
.offcanvas-start{
  width: 262px !important;
}
.offcanvas-backdrop.show{
  opacity: 0 !important;
}

.offcanvas-header{
  padding: 0 30px 0 30px !important;
  margin-top: 49px !important;
}

.btn-close{
  margin-left: 30px !important;
  background: url('../../../assets/images/close.svg') center no-repeat !important;
  opacity: 1 !important;
}
.offcanvas-body{
  padding: 32px 36px 30px 32px !important
} 

.offcanvas-body .row span{
  word-break: break-word;
}
.arrow{
  top:0;
  text-align: right;
}

.offcanvas-body .row-margin{
  margin-top: 26px;
}

.offcanvas-body .row:hover{
  padding: 0!important;
}
.offcanvas-body a{
  color: #545454;
}
.activePage{
  fill:#008393;
  color:#008393
}
.active{
  color:#008393 !important;
}

@media (min-width:600px) {
  .offcanvas-start{
    width: 292px !important;
  }
}
